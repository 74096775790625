import Headroom from 'headroom.js';

const header = document.querySelector('.site-header');

if (header) {

    const headroom = new Headroom(
        header,
        {
            offset: 50,
        }
    );

    headroom.init();

}